import React from "react"
import {Helmet} from "react-helmet"
import { withPrefix} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {fab, faTwitterSquare, faFacebook, faLinkedin, faGithub} from "@fortawesome/free-brands-svg-icons";
library.add(fab, faTwitterSquare, faFacebook, faLinkedin, faGithub);


const JeuDonneesPage = ( props ) => {

    return (
        
        <Layout>
          <Helmet>
            <script src={withPrefix('iframe.js')} type="text/javascript" />
          </Helmet>
           <SEO title="Datasets" />  
           
            <section id="banner_parallax" className="inner_page_banner slide_banner1">
                <div className="container">
                    <div className="row">
                    <div className="col-md-6">
                        <div className="full">
                            <div className="slide_cont">
                            <h2>Jeu de données</h2>
                            
                            <ul className="breadcrumb_list d-flex flex-wrap">
                                <li><a href="/">Accueil</a></li>
                                <li> <span>Jeu de données</span> </li>
                                {/* <li> <span>{actualites.field_texte_entete_img_g.value}</span> </li> */}
                            </ul>
                                </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="full">
                            <div className="slide_pc_img wow fadeInRight" data-wow-delay="1s" data-wow-duration="2s">
                                <img src="/images/air_img.png" alt="#" /> 
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
          </section>
      
          {/* You need to add proxy_hide_header X-Frame-Options; to the reverse of the target website (see ) */}
          <section className="the__content _blog blog_listing">
            <iframe title=" jeu de données" id="marque-blanche" className="marque_blanche" src="https://grandest.data4citizen.com/portailFullscreen" width="600" height="500"/> 
          </section>
               
          <footer className="footer_style_2">
         <div className="footer_top">
            <div className="container">
               <div className="row">
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 margin_bottom_30">
                     <div className="full width_9" style={{marginBottom: "25px"}} > <a rel="noreferrer" href="/"><img style={{backgroundColor: "#1e72bc"}} className="img-responsive" width="200" src="/images/logo.png" alt="#"/></a> </div>
                     <div className="full width_9">
              
                     </div>
                     
                  </div>
                  
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 margin_bottom_30">
                              <div className="full">
                                <div className="footer_blog_2 width_9">
                                    <h3>GrandEst Partenariat</h3><br/>
                                    <p><a rel="noreferrer"  href="http://www.prefectures-regions.gouv.fr/grand-est/" target="_blank"><img  typeof="foaf:Image" className="img-responsive" src="https://www.geograndest.fr/portail/sites/default/files/logo_footer/logo_bm_reg-grand-est-sur-gge.png" width="100" height="auto" alt="Préfecture de la région Grand  Est" title="Préfecture de la région Grand  Est"/></a></p>
                                    <p><a rel="noreferrer"  href="https://www.grandest.fr" target="_blank"><img alt ="grandest" typeof="foaf:Image" className="img-responsive" src="https://www.geograndest.fr/portail/sites/default/files/logo_footer/grandest.png" width="100" height="auto"/></a></p>
                                    <p><a rel="noreferrer"  href="https://europa.eu/european-union/index_fr" target="_blank"><img alt ="eauopean union" typeof="foaf:Image" className="img-responsive" src="https://www.geograndest.fr/portail/sites/default/files/embleme_ue_titre_bleu.png" width="100" height="auto"/></a></p>
                                    <p><a rel="noreferrer"  href="https://www.grandest.fr/europe-grandest" target="_blank"><img alt ="europ grandest" typeof="foaf:Image" className="img-responsive" src="https://www.geograndest.fr/portail/sites/default/files/grand_est_feder_deroule-01.jpg" width="100" height="auto"/></a></p>
                                   
                                </div>
                              </div>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 margin_bottom_30">
                              <div className="full">
                                <div className="footer_blog_2 width_9">
                                    <h3>Liens</h3><br/>
                                    <p><a rel="noreferrer"  href="https://www.atmo-auvergnerhonealpes.fr/" target="_blank">Mentions Légales</a> </p>
                                    <p><a rel="noreferrer"  href="https://www.atmo-nouvelleaquitaine.org/" target="_blank">Disponibilité des services</a></p>
                                    <p><a rel="noreferrer"  href="https://www.geograndest.fr/portail/fr/contact" target="_blank">Contactez-nous</a> </p>
                                    
                                </div>

                               
                                
                                
                              </div>
                          </div>
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-2 margin_bottom_30">
                           <div className="full">
                              <div className="footer_blog_2">
                                 <h3>Nous suivre</h3><br/>
                              </div>
                           </div>
                           <div className="full">
                           <ul className="footer-links">
                                 <li><a rel="noreferrer" href="https://www.facebook.com/regiongrandest" target="_blank" rel="noreferrer" title="GrandEst on Facebook"><FontAwesomeIcon width ="40" height="40" className='icon-social-media' icon={['fab', 'facebook']} /> </a></li>
                                 <li><a rel="noreferrer" href="https://www.instagram.com/laregiongrandest/" target="_blank" rel="noreferrer" title="GrandEst on instagram"><FontAwesomeIcon width ="40" height="40" className='icon-social-media' icon={['fab', 'instagram']} /> </a></li>
                                 <li><a rel="noreferrer" href="https://twitter.com/regiongrandest?lang=fr" target="_blank" rel="noreferrer" title="GrandEst on Twitter"><FontAwesomeIcon width ="40" height="40"  className='icon-social-media' icon={['fab', 'twitter']} /></a></li>
                                 <li><a rel="noreferrer" href="https://www.linkedin.com/organization-guest/company/regiongrandest?challengeId=AQGIJdTmgQRQAQAAAXMzws4rWaGVPB5eUG24az3sAMsZolpawWcG4um0fs1WTvdukzeRwSfup8kKB_fSS3_dNgn7ZQhzJSOvSw&amp;submissionId=d1e1cf2c-8f18-2016-45ae-34bbea0ab656" target="_blank" rel="noreferrer" title="GrandEst on LinkedIn"><FontAwesomeIcon width ="40" height="40"  className='icon-social-media' icon={['fab', 'linkedin']} /></a></li>
                                 <li><a rel="noreferrer" href="https://www.youtube.com/RegionGrandEst" target="_blank" rel="noreferrer" title="GrandEst on Youtube"><FontAwesomeIcon width ="40" height="40"  className='icon-social-media' icon={['fab', 'youtube']} /></a></li>

                              </ul>
                           </div>
                  </div>
        
               </div>
            </div>
         </div>
    
         <div className="footer_bottom">
            <p ><strong>GrandEst</strong> © Tous droits réservés 2020</p>
         
         </div>
    </footer>                         
    
          </Layout>
            );

    
}
   
  

   
    

export default JeuDonneesPage
